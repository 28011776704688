import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserDutyEnum } from 'src/enumerators';
import { environment } from 'src/environments/environment';
import { Contract, ItemAdjustment } from 'src/models';
import { ContractAuxiliarItemBalanceHistory, ContractBalanceHistory, ContractDeviceItemBalanceHistory, ContractHorizontalItemBalanceHistory, ContractRetroreflectorization, ContractServiceItemBalanceHistory, ContractTrafficLightBalance, ContractVerticalItemBalanceHistory } from 'src/models/contract';
import { AuthService } from './auth.service';
import { RestApiService } from './rest-api.service';
interface IDistrict {
  nome: string;
}
@Injectable({
  providedIn: 'root',
})
export class ContractService extends RestApiService {
  private envEndpoint = environment.endpoint();
  uri = environment.uri();
  _urlContracts = '/contracts';
  _urlContractsFull = '/contracts?full=true';
  _urlFilms = '/road-sign-films';
  _urlSubs = '/road-sign-substrates';
  _urlMarkPaint = '/road-sign-marking-paints';
  _urlMembers = '/workspace-members';
  _urlUploadFiles = '/contracts/:id/files';
  _urlDownloadFiles = '/contracts/files/:id';
  _urlUploadImage = '/contracts/:id/image';
  _urlContractVerticalItem = '/contracts/vertical-items/:id';
  _urlContractVerticalItemBalance = '/contracts/vertical-items/:id/balance';
  _urlContractHorizontalItem = '/contracts/horizontal-items/:id';
  _urlContractHorizontalItemBalance = '/contracts/horizontal-items/:id/balance';
  _urlContractDeviceItem = '/contracts/device-items/:id';
  _urlContractDeviceItemBalance = '/contracts/device-items/:id/balance';
  _urlContractServiceItem = '/contracts/service-items/:id';
  _urlContractServiceItemBalance = '/contracts/service-items/:id/balance';
  _urlContractAuxiliarItemBalance = '/contracts/auxiliar-items/:id/balance';
  _urlContractRetro = '/contracts/retroreflectorizations';
  _urlContractRetroContractId = '/contracts/:contractId/retroreflectorization';
  _urlContractRetroId = '/contracts/retroreflectorizations/:id';
  _urlContractVerticalItemsFiles = '/contracts/vertical-items/:id/files'
  _urlContractHorizontalItemsFiles = '/contracts/horizontal-items/:id/files'
  _urlContractDeviceItemsFiles = '/contracts/device-items/:id/files'
  _urlContractServiceItemsFiles = '/contracts/service-items/:id/files'
  _urlContractAuxiliarItemsFiles = '/contracts/auxiliar-items/:id/files'
  _urlDownloadContractVerticalItemsFiles = '/contracts/vertical-items/files/:id'
  _urlDownloadContractHorizontalItemsFiles = '/contracts/horizontal-items/files/:id'
  _urlDownloadContractDeviceItemsFiles = '/contracts/device-items/files/:id'
  _urlDownloadContractServiceItemsFiles = '/contracts/service-items/files/:id'
  _urlDownloadContractAuxiliarItemsFiles = '/contracts/auxiliar-items/files/:id'
  _urlContractBalanceHistory = '/contracts/:id/balance'
  _urlContractTrafficLightBalance = '/contracts/:id/traffic-light-balance'
  _urlRemoveContractTrafficLightBalance = '/contracts/:contractId/traffic-light-balance/:id'

  constructor(private _http: HttpClient, private _auth: AuthService) {
    super(_http)
  }

  // Cria novo contrato
  submitContract(reqBody) {
    const url = this.envEndpoint + this._urlContracts;
    return this._http.post(url, reqBody);
  }

  // Lista membros da WORKSPACE
  listAllWorkspaceUsers(userDuty?: UserDutyEnum, registeredSignaling?: boolean) {
    let url = this.envEndpoint + this._urlMembers;
    if (userDuty) url += `?userDuty=${userDuty}`;
    if (registeredSignaling) url += `?registeredSignaling=${registeredSignaling}`;
    return this._http.get(url);
  }

  // Retorna um único WORKSPACE member a partir do email
  getWorkspaceMember(email) {
    const url = this.envEndpoint + this._urlMembers;
    const encoded = encodeURIComponent(email);
    const params = new HttpParams().set('email', encoded);
    return this._http.get(url, { params: params });
  }

  // Lista todos os contratos vinculádos ao JWT
  public listContracts() {
    const url = this.envEndpoint + this._urlContracts;
    return this._http.get(url);
  }

  // Lista todos os contratos com dados completos vinculádos ao JWT
  public listFullContracts() {
    const url = this.envEndpoint + this._urlContractsFull;
    return this._http.get(url);
  }

  // Remove o contrato a partir do ID
  public removeContract(id) {
    const url = this.envEndpoint + this._urlContracts + `/${id}`;
    return this._http.delete(url);
  }

  // PUT atualiza o contrato
  public updateContract(contractId, body) {
    const url = this.envEndpoint + this._urlContracts + `/${contractId}`;
    return this._http.put(url, body);
  }

  // Retorna contrato unico
  public getContract(contractId: string) {
    const url = this.envEndpoint + this._urlContracts + `/${contractId}`;
    return this._http.get(url);
  }
  // Popula opções de película com ID
  populateFilms() {
    const url = this.envEndpoint + this._urlFilms;
    return this._http.get(url);
  }

  populateMarkPainting() {
    const url = this.envEndpoint + this._urlMarkPaint;
    return this._http.get(url);
  }

  getContractBalanceHistory(contractId: string) {
    const url = this.envEndpoint + this._urlContractBalanceHistory.replace(':id', contractId);
    return this._http.get<ContractBalanceHistory[]>(url);
  }

  getContractVerticalItemBalanceHistory(contractVerticalIemId: string) {
    const url = this.envEndpoint + this._urlContractVerticalItemBalance.replace(':id', contractVerticalIemId);
    return this._http.get<ContractVerticalItemBalanceHistory[]>(url);
  }

  getContractHorizontalItemBalanceHistory(contractHorizontalIemId: string) {
    const url = this.envEndpoint + this._urlContractHorizontalItemBalance.replace(':id', contractHorizontalIemId);
    return this._http.get<ContractHorizontalItemBalanceHistory[]>(url);
  }

  getContractDeviceItemBalanceHistory(contractDeviceIemId: string) {
    const url = this.envEndpoint + this._urlContractDeviceItemBalance.replace(':id', contractDeviceIemId);
    return this._http.get<ContractDeviceItemBalanceHistory[]>(url);
  }
  
  getContractServiceItemBalanceHistory(contractServiceIemId: string) {
    const url = this.envEndpoint + this._urlContractServiceItemBalance.replace(':id', contractServiceIemId);
    return this._http.get<ContractServiceItemBalanceHistory[]>(url);
  }

  getContracAuxiliarItemBalanceHistory(contractAuxiliarIemId: string) {
    const url = this.envEndpoint + this._urlContractAuxiliarItemBalance.replace(':id', contractAuxiliarIemId);
    return this._http.get<ContractAuxiliarItemBalanceHistory[]>(url);
  }

  // Popula opções de substratos com ID
  populateSubs() {
    const url = this.envEndpoint + this._urlSubs;
    return this._http.get(url);
  }
  populateStates() {
    return this._http.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
  }

  populateCities(state) {
    return this._http.get<IDistrict[]>(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/distritos`,
    );
  }

  uploadContractFile(files: Array<any>, contractId: string) {
    let reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
      FormData: 'true',
    });

    let formData = new FormData();
    for (let file of files) {
      formData.append('files', file.file);
    }
    const url = this.envEndpoint + this._urlUploadFiles.replace(':id', contractId);
    return this._http.post(url, formData, { headers: reqHeaders });
  }

  downloadFile(fileId: string) {
    const url = this.envEndpoint + this._urlDownloadFiles.replace(':id', fileId);
    return this._http.get<{ url: string }>(url);
  }

  deleteFile(fileId: string) {
    const url = this.envEndpoint + this._urlDownloadFiles.replace(':id', fileId);
    return this._http.delete<{ url: string }>(url);
  }
  uploadImages(file: File, contractId: string) {
    const reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
      FormData: 'true',
    });
    const formData = new FormData();
    formData.append('file', file, file.name);
    const url = this.envEndpoint + this._urlUploadImage.replace(':id', contractId);
    return this._http.post(url, formData, { headers: reqHeaders });
  }
  // Retorna o item do contrato pela ID
  public getContractVerticalItem(contractItemId: string) {
    const url = this.envEndpoint + this._urlContractVerticalItem.replace(':id', contractItemId);
    return this._http.get(url);
  }

  // Retorna o item do contrato pela ID
  public getContractHorizontalItem(contractItemId: string) {
    const url = this.envEndpoint + this._urlContractHorizontalItem.replace(':id', contractItemId);
    return this._http.get(url);
  }

  // Retorna o item do contrato pela ID
  public getContractDeviceItem(contractItemId: string) {
    const url = this.envEndpoint + this._urlContractDeviceItem.replace(':id', contractItemId);
    return this._http.get(url);
  }

  // Retorna o item do contrato pela ID
  public getContractServiceItem(contractItemId: string) {
    const url = this.envEndpoint + this._urlContractServiceItem.replace(':id', contractItemId);
    return this._http.get(url);
  }

  // Persiste os dados de retro
  createContractRetro(retroData: ContractRetroreflectorization) {
    const url = this._urlContractRetro;
    return this.post(url, retroData, ContractRetroreflectorization);
  }

  // Retorna os dados de retro pelo id do contrato
  getContractRetro(contractId: string) {
    const url = this._urlContractRetroContractId.replace(':contractId', contractId);
    return this.get(url, ContractRetroreflectorization);
  }

  // Altera os dados de retro
  updateContractRetro(retroData: ContractRetroreflectorization) {
    const url = this._urlContractRetroId.replace(':id', retroData.id);
    return this.put(url, retroData, ContractRetroreflectorization);
  }

  // Verifica se existem reajustes nos itens do contrato
  checkForContractAdjustments(contract: Contract) {
    // Grupos de verticais
    contract?.verticalGroups.forEach((verticalGroup) => {
      verticalGroup?.verticalItems.forEach((verticalItem) => {
        const adjustmentOnDate = this.getItemWithClosestDate(verticalItem?.adjustments);
        verticalItem.unitValue = adjustmentOnDate ? adjustmentOnDate.unitValue : verticalItem.unitValue;
      });
    });

    // Grupos de horizontais
    contract?.horizontalGroups.forEach((horizontalGroup) => {
      horizontalGroup?.horizontalItems.forEach((horizontalItem) => {
        const adjustmentOnDate = this.getItemWithClosestDate(horizontalItem?.adjustments);
        horizontalItem.unitValue = adjustmentOnDate ? adjustmentOnDate.unitValue : horizontalItem.unitValue;
      });
    });

    // Grupos de dispositivos
    contract?.deviceGroups.forEach((deviceGroup) => {
      deviceGroup?.deviceItems.forEach((deviceItem) => {
        const adjustmentOnDate = this.getItemWithClosestDate(deviceItem?.adjustments);
        deviceItem.unitValue = adjustmentOnDate ? adjustmentOnDate.unitValue : deviceItem.unitValue;
      });
    });

    // Grupos de serviços
    contract?.serviceGroups.forEach((serviceGroup) => {
      serviceGroup?.serviceItems.forEach((serviceItem) => {
        const adjustmentOnDate = this.getItemWithClosestDate(serviceItem?.adjustments);
        serviceItem.unitValue = adjustmentOnDate ? adjustmentOnDate.unitValue : serviceItem.unitValue;
      });
    });
  }

  // Verifica as datas de adjustments e retorna o item com a data vigente ou anterior
  getItemWithClosestDate(adjustments: ItemAdjustment[]) {
    return adjustments.reduce((closest, currentItem) => {
      const itemDate = new Date(currentItem?.baseDate);

      if (
        itemDate.getTime() <= new Date().getTime() &&
        (!closest ||
          Math.abs(new Date().getTime() - itemDate.getTime()) <
          Math.abs(new Date().getTime() - new Date(closest.baseDate).getTime()))
      ) {
        return currentItem;
      }

      return closest;
    }, undefined);
  }

  // Vertical
  postContractVerticalItemFile(files: Array<any>, verticalItemId: string) {
    let reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
      FormData: 'true',
    });

    let formData = new FormData();
    for (let file of files) {
      formData.append('files', file.file);
    }
    const url = this.envEndpoint + this._urlContractVerticalItemsFiles.replace(':id', verticalItemId);
    return this._http.post(url, formData, { headers: reqHeaders });
  }

  getContractVerticalItemFIle(fileId: string) {
    const url = this.envEndpoint + this._urlDownloadContractVerticalItemsFiles.replace(':id', fileId);
    return this._http.get<{ url: string }>(url);
  }

  deleteContractVerticalItemFile(fileId: string) {
    const url = this.envEndpoint + this._urlDownloadContractVerticalItemsFiles.replace(':id', fileId);
    return this._http.delete<{ url: string }>(url);
  }

  // Horizontal
  postContractHorizontalItemFile(files: Array<any>, horizontalItemId: string) {
    let reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
      FormData: 'true',
    });

    let formData = new FormData();
    for (let file of files) {
      formData.append('files', file.file);
    }
    const url = this.envEndpoint + this._urlContractHorizontalItemsFiles.replace(':id', horizontalItemId);
    return this._http.post(url, formData, { headers: reqHeaders });
  }

  getContractHorizontalItemFIle(fileId: string) {
    const url = this.envEndpoint + this._urlDownloadContractHorizontalItemsFiles.replace(':id', fileId);
    return this._http.get<{ url: string }>(url);
  }

  deleteContractHorizontalItemFile(fileId: string) {
    const url = this.envEndpoint + this._urlDownloadContractHorizontalItemsFiles.replace(':id', fileId);
    return this._http.delete<{ url: string }>(url);
  }

  // Device
  postContractDeviceItemFile(files: Array<any>, deviceItemId: string) {
    let reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
      FormData: 'true',
    });

    let formData = new FormData();
    for (let file of files) {
      formData.append('files', file.file);
    }
    const url = this.envEndpoint + this._urlContractDeviceItemsFiles.replace(':id', deviceItemId);
    return this._http.post(url, formData, { headers: reqHeaders });
  }

  getContractDeviceItemFIle(fileId: string) {
    const url = this.envEndpoint + this._urlDownloadContractDeviceItemsFiles.replace(':id', fileId);
    return this._http.get<{ url: string }>(url);
  }

  deleteContractDeviceItemFile(fileId: string) {
    const url = this.envEndpoint + this._urlDownloadContractDeviceItemsFiles.replace(':id', fileId);
    return this._http.delete<{ url: string }>(url);
  }

  // Service
  postContractServiceItemFile(files: Array<any>, serviceItemId: string) {
    let reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
      FormData: 'true',
    });

    let formData = new FormData();
    for (let file of files) {
      formData.append('files', file.file);
    }
    const url = this.envEndpoint + this._urlContractServiceItemsFiles.replace(':id', serviceItemId);
    return this._http.post(url, formData, { headers: reqHeaders });
  }

  getContractServiceItemFIle(fileId: string) {
    const url = this.envEndpoint + this._urlDownloadContractServiceItemsFiles.replace(':id', fileId);
    return this._http.get<{ url: string }>(url);
  }

  deleteContractServiceItemFile(fileId: string) {
    const url = this.envEndpoint + this._urlDownloadContractServiceItemsFiles.replace(':id', fileId);
    return this._http.delete<{ url: string }>(url);
  }

  // Auxiliar
  postContractAuxiliarItemFile(files: Array<any>, auxiliarItemId: string) {
    let reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
      FormData: 'true',
    });

    let formData = new FormData();
    for (let file of files) {
      formData.append('files', file.file);
    }
    const url = this.envEndpoint + this._urlContractAuxiliarItemsFiles.replace(':id', auxiliarItemId);
    return this._http.post(url, formData, { headers: reqHeaders });
  }

  getContractAuxiliarItemFIle(fileId: string) {
    const url = this.envEndpoint + this._urlDownloadContractAuxiliarItemsFiles.replace(':id', fileId);
    return this._http.get<{ url: string }>(url);
  }

  deleteContractAuxiliarItemFile(fileId: string) {
    const url = this.envEndpoint + this._urlDownloadContractAuxiliarItemsFiles.replace(':id', fileId);
    return this._http.delete<{ url: string }>(url);
  }

  postContractTrafficLightBalance(contractId: string, contractTrafficLightBalance: ContractTrafficLightBalance) {
    const url = this.envEndpoint + this._urlContractTrafficLightBalance.replace(':id', contractId);
    return this._http.post(url, contractTrafficLightBalance);
  }

  getContractTrafficLightBalance(contractId: string) {
    const url = this.envEndpoint + this._urlContractTrafficLightBalance.replace(':id', contractId);
    return this._http.get(url);
  }

  removeContractTrafficLightBalance(contractTrafficLightBalance: ContractTrafficLightBalance) {
    const url = this.envEndpoint + this._urlRemoveContractTrafficLightBalance
      .replace(':contractId', contractTrafficLightBalance.contractId)
      .replace(':id', contractTrafficLightBalance.id) ;

    return this._http.delete(url);
  }
}
