import { formatCurrency, formatDate } from '@angular/common';
import { EventEmitter, Injectable, Output } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { enumerators } from 'src/dictionaries/enum.pt-br';
import { svgElement } from 'src/dictionaries/svg-sign';
import { SignalingImageSituationEnum, SignalingTypeEnum, SystemModuleEnum } from 'src/enumerators';
import { ServiceOrder, Task, Team, Vehicle } from 'src/models';
import { Route } from 'src/models/path';
import { AuthService } from './auth.service';
import { OrganizationService } from './organization.service';
import { ReportService } from './report.service';
import { environment } from 'src/environments/environment';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root',
})
export class PdfMakeService {
  // Array com conteudos para plotar no relatório
  docContent: any[] = [];
  @Output() pdfReadyEmit = new EventEmitter<string>();
  @Output() pdfReadyTextEmit = new EventEmitter<string>();

  constructor(
    private _orgService: OrganizationService,
    private _authService: AuthService,
    private _reportService: ReportService,
  ) { }

  async buildPDF(tasks: Task[], type: SystemModuleEnum, startsAt?, endsAt?, serviceOrders?: ServiceOrder[], routes?: Route[], requesters?: any[], vehicleList?: Vehicle[], teamList?: Team[], imageView?: boolean, financial?: boolean, signList?: Array<any>) {
    const separatorLine = { canvas: [{ type: 'line', x1: 0, y1: 2, x2: 595 - 2 * 40, y2: 2, lineWidth: 1 }] }; // linha divisória para titulos
    const my5 = { text: '', margin: [0, 5, 0, 5] }; // margin-y
    // GET dos dados da organização
    this._orgService.getCurrentOrg().subscribe(async (orgRes) => {
      const orgTitle = {
        columns: [
          { image: await this.getBase64ImageFromURL('assets/icons/org-icon.png', false), width: 10, margin: [0, 10, 5, 10] },
          {
            text: 'Organização',
            bold: true,
            width: 'auto',
            alignText: 'center',
            margin: [5, 10, 0, 10],
          },
        ],
      };

      const orgName = {
        columns: [
          { text: 'Nome da Organização:', bold: true, width: 140, margin: [0, 20, 0, 0] },
          { text: orgRes.name, margin: [0, 20, 0, 0] },
        ],
      };
      const orgCNPJ = {
        columns: [{ text: 'CNPJ da Organização:', bold: true, width: 140 }, { text: orgRes.cnpj }],
      };
      const docEmitter = {
        columns: [
          { text: 'Emissor do relatório:', bold: true, width: 140 },
          { text: this._authService?.loggedUser?.firstName + ' ' + this._authService?.loggedUser?.lastName },
        ],
      };
      const emitDate = {
        columns: [
          { text: 'Data de emissão:', bold: true, width: 140 },
          { text: formatDate(new Date(), 'dd/MM/yyyy', 'pt-BR') },
        ],
      };
      const execDate = {
        columns: [
          { text: 'Período de Execução:', bold: true, width: 140, margin: [0, 20, 0, 0] },
          startsAt
            ? {
              text:
                formatDate(startsAt || new Date(), 'dd/MM/yyyy', 'pt-BR') +
                ' - ' +
                formatDate(endsAt || new Date(), 'dd/MM/yyyy', 'pt-BR'),
              margin: [0, 20, 0, 0],
            }
            : { text: 'Não determinado.', margin: [0, 20, 0, 0] },
        ],
      };

      let execOSDate;
      if (serviceOrders) {
        execOSDate = {
          columns: [
            { text: 'Período de Execução das OS:', bold: true, width: 140 },
            serviceOrders.map(order => formatDate(order.executionDate || new Date(), 'dd/MM/yyyy', 'pt-BR') + ' - (' + this.numberOS(order, 'date', serviceOrders) + ')')
          ],
        };
      };

      var taskNames = { stack: [] };

      if (serviceOrders) {
        serviceOrders.forEach((order, index) => {
          let i = index + 1;
          taskNames.stack.push({ text: order.name + ' - (' + i + ')' });
        });

      } else if (routes) {
        routes.forEach((route) => {
          taskNames.stack.push({ text: route.name });
        });
      } else if (tasks) {
        tasks.forEach((task) => {
          taskNames.stack.push({ text: task.name });
        });
      }
      var selectedTasks = type == SystemModuleEnum.SignalingRegister ? undefined : {
        columns: [
          { text: `${type == SystemModuleEnum.Task ? 'Tarefas' : type == SystemModuleEnum.ServiceOrder ? 'Ordens' : 'Rotas'} Selecionadas:`, bold: true, width: 140 },
          taskNames,
        ],
      };

      var selectedRequesters = type == SystemModuleEnum.ServiceOrder ? {
        columns: [
          { text: `Solicitantes Selecionados:`, bold: true, width: 140 },
          requesters.map(r => r + ' - ' + this.numberOS(r, 'requester', serviceOrders)),
        ],
      } : undefined;

      this.docContent.push([
        {
          stack: [orgTitle, separatorLine, orgName, orgCNPJ, docEmitter, emitDate, type == SystemModuleEnum.ServiceOrder ? '' : execDate],
          unbreakable: true,
        },
        selectedTasks,
        execOSDate,
        selectedRequesters,
        my5,
      ]);

      if (type != SystemModuleEnum.SignalingRegister) {

        let signQuantity = this.countTotalSignals(tasks);
        let unitValue = 70 / signQuantity;
        let sum = 0;
        for (let task of tasks) {
          await this.buildTaskContent(task, serviceOrders, teamList, vehicleList, imageView, unitValue, financial, sum);
          sum += unitValue * task['reportSigns'].length;
        }
      } else {
        let signQuantity = signList.length;
        let unitValue = 70 / signQuantity;
        let i = 0;

        for (let sign of signList) {
          await this.buildSignContent(sign, imageView, financial);
          this.pdfReadyEmit.emit((20 + (unitValue * i)).toFixed(1));
          i += 1;
        }

      }
      new Promise<any>((resolve) => {
        this.pdfReadyEmit.emit('90');
        this.pdfReadyTextEmit.emit('baixando o PDF...')
        this.createPDF(type, orgRes.sinalesReport).then((res) => {
          this.pdfReadyEmit.emit('99');
          setTimeout(() => {
            resolve(res);
            this.pdfReadyEmit.emit('100');
          });
        });
      });
    });
  }

  countTotalSignals(tasks: Task[]): number {
    return tasks.map(task => task['reportSigns'].length).reduce((acc, val) => acc + val, 0);
  }

  async buildTaskContent(task: Task, serviceOrders?: Array<ServiceOrder>, teams?: Team[], vehicles?: Vehicle[], imageView?: boolean, unitValue?: number, financial?: boolean, sum?: number): Promise<any> {
    // Dados das tarefas
    const taskTitle = {
      columns: [
        {
          image: await this.getBase64ImageFromURL('assets/icons/list-check.png', false),
          width: 15,
          margin: [0, 11, 10, 10],
        },
        {
          text: task.name,
          bold: true,
          width: 'auto',
          alignText: 'center',
          margin: [5, 10, 0, 10],
        },
      ],
    };
    const taskName = {
      columns: [
        { text: 'Nome da Tarefa:', bold: true, width: 140, margin: [0, 20, 0, 0] },
        { text: task.name, margin: [0, 20, 0, 0] },
      ],
    };
    const taskType = {
      columns: [{ text: 'Tipo de Tarefa:', bold: true, width: 140 }, { text: enumerators.TaskType[task.taskType] }],
    };
    let taskOS;
    if (serviceOrders) {
      const order: any = task.serviceOrderId ? serviceOrders.find(os => os.id == task.serviceOrderId) : { name: 'Sem Ordem de Serviço' };
      taskOS = {
        columns: [{ text: 'Ordem de Serviço Vinculada:', bold: true, width: 140 }, { text: order.name }],
      };
    };

    let team: any = teams?.find(t => t.id == task.teamId);
    if (!team) team = task.teamId ? { name: task?.team?.name ?? 'Equipe Não Cadastrada' } : { name: 'Sem Equipe' };

    const taskTeam = {
      columns: [{ text: 'Equipe Vinculada:', bold: true, width: 140 }, { text: team.name }],
    };

    let vehicle: any = vehicles?.find(v => v.id == task.vehicleId);
    if (!vehicle) vehicle = task.vehicleId ? { name: task?.vehicle?.licensePlate ?? 'Veículo Não Cadastrado' } : { name: 'Sem veículo' };

    const taskVehicle = {
      columns: [{ text: 'Veículo Vinculado:', bold: true, width: 140 }, { text: vehicle.licensePlate }],
    };
    const taskExecDate = {
      columns: [
        { text: 'Período de Execução:', bold: true, width: 140 },
        {
          text:
            formatDate(task.executionDate, 'dd/MM/yyyy', 'pt-BR') ||
            'Sem Data de Execução' + ' - ' + formatDate(task.endDate, 'dd/MM/yyyy', 'pt-BR') ||
            'Sem Data de Término',
        },
      ],
    };
    const taskStatus = {
      columns: [{ text: 'Status da Tarefa:', bold: true, width: 140 }, { text: enumerators.TaskStatus[task.status] }],
    };
    const taskPriority = {
      columns: [
        { text: 'Prioridade da Tarefa:', bold: true, width: 140 },
        { text: enumerators.TaskPriority[task.priority] },
      ],
    };

    // Enviando os componentes para o PDF
    this.docContent.push([
      {
        stack: [
          taskTitle,
          { canvas: [{ type: 'line', x1: 0, y1: 2, x2: 595 - 2 * 40, y2: 2, lineWidth: 1 }] },
          taskName,
          taskType,
          taskOS,
          taskTeam,
          taskVehicle,
          taskExecDate,
          taskStatus,
          taskPriority,

        ],
        unbreakable: true,
      },
    ]);

    let i = 0;
    for (let sign of task['reportSigns']) {
      await this.buildSignContent(sign, imageView, financial);
      this.pdfReadyEmit.emit((20 + sum + (unitValue * i)).toFixed(1));
      i += 1;
    }
  }

  async buildSignContent(sign, imageView: boolean, financial?: boolean) {

    const signName = {
      columns: [
        {
          image: await this.getBase64ImageFromURL(svgElement.Signaling[sign?.signCode], false),
          width: 20,
          margin: [0, 10, 0, 10],
        },
        {
          text: enumerators.Signaling[sign?.signCode],
          bold: true,
          width: 'auto',
          alignText: 'center',
          margin: [10, 12, 0, 10],
        },
      ],
    };

    const createdAt = {
      columns: [
        { text: 'Data de Cadastramento:', bold: true, width: 140 },
        { text: formatDate(sign?.createdAt, 'dd/MM/yyyy', 'pt-BR') },
      ],
    };
    const latlng = !sign?.locations
      ? ' [' + (sign?.lat * 1).toFixed(6) + ', ' + (sign?.lng * 1).toFixed(6) + ']'
      : ' [' + (sign?.locations[0].lat * 1).toFixed(6) + ', ' + (sign?.locations[0]?.lng * 1).toFixed(6) + ']'

    const activityAddress = {
      columns: [
        { text: 'Localização da Sinalização:', bold: true, width: 140 },
        {
          text:
            (sign?.address?.road ? sign?.address?.road + ', ' : '') +
            (sign?.address?.road
              ? sign?.address?.house_number
                ? sign?.address?.house_number + ' - '
                : 's/ nº - '
              : '') +
            (sign?.address?.suburb ? sign?.address?.suburb + ', ' : '') +
            (sign?.address?.city ? sign?.address?.city : '') +
            (sign?.address?.postcode ? ', ' + sign?.address?.postcode : '') + '\n'
            + latlng,
        },
      ],
    };

    const contractItem = {
      columns: [
        { text: 'Item do Contrato:', bold: true, width: 140 },
        {
          text: sign?.contractSignItem?.name,
        },
      ],
    };

    // Item do suporte
    var supportItem = {};
    let supportItemSign = sign.supportItem ? sign.supportItem : sign.contractSupportItem;
    if (supportItemSign) {
      let stack = [{
        columns: [
          { text: 'Item do Suporte:', bold: true, width: 140 },
          { text: supportItemSign?.name, },
        ],
      }]

      if (financial) stack.push({
        columns: [
          { text: 'Valor do Suporte:', bold: true, width: 140 },
          { text: formatCurrency(supportItemSign?.unitValue, 'pt-BR', 'R$'), },
        ],
      },)
      supportItem = {
        stack: stack,
      };
    }

    const dimensionType = {
      columns: [
        { text: 'Tipo de Dimensão:', bold: true, width: 140, margin: [0, 0, 10, 0] },
        { text: sign?.type !== SignalingTypeEnum.Device ? sign?.dimensionType : 'APLICAÇÃO' },
      ],
    };

    const tableContent = await this.getTableMeasurement(sign, financial);

    const dimensionTable = {
      style: 'tableExample',
      table: {
        headerRows: 1,
        widths: ['auto', 85, '*', '*', 80, '*', '*'],
        body: [
          [
            { text: 'Nº', style: 'tableHeader', border: [false, false, false, true] },
            { text: 'Data de Execução', style: 'tableHeader', border: [false, false, false, true] },
            { text: 'Unidades (und)', style: 'tableHeader', border: [false, false, false, true] },
            { text: 'Altura (m)', style: 'tableHeader', border: [false, false, false, true] },
            { text: 'Largura (m)', style: 'tableHeader', border: [false, false, false, true] },
            { text: 'Área Total (m²)', style: 'tableHeader', border: [false, false, false, true] },
            { text: financial ? 'Valor Total' : '', style: 'tableHeader', border: [false, false, false, true] },
          ],
          ...tableContent,
        ],
      },
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex % 2 === 0 ? null : '#F1F1F4';
        },
      },
      margin: [0, 10, 0, 10],
    };

    // Recebendo as imagens
    var images = {
      before: [],
      beforeBreakLine: [],
      after: [],
      afterBreakLine: [],
    };

    console.log({ imageView });

    console.log({ sign });
    
    if (imageView) {
      const signalingFiles =
        sign?.type == SignalingTypeEnum.Vertical
          ? sign?.verticalSignalingFiles || sign?.verticalSignaling?.verticalSignalingFiles
          : sign?.type == SignalingTypeEnum.Horizontal
          ? sign?.horizontalSignalingFiles
          : sign?.deviceSignalingFiles;

      for (let file of signalingFiles) {
        if (file['file']['extension'] != 'mp4') {
          if (file.situation == SignalingImageSituationEnum.Before) {
            images[images.before.length <= 2 ? 'before' : 'beforeBreakLine'].push({
              image: await this.getBase64ImageFromURL(file.url, true),
              width: 170,
              margin: [0, 15, 0, 0],
            });
          } else {
            images[images.after.length <= 2 ? 'after' : 'afterBreakLine'].push({
              image: await this.getBase64ImageFromURL(file.url, true),
              width: 170,
              margin: [0, 15, 0, 0],
            });
          }
        }
      }

      var beforeImage = {
        columns: images.before.length ? [...images.before] : [{ text: 'Não há registro fotográfico do Local.' }],
      };

      var beforeImageBreakLine = {
        columns: [...images.beforeBreakLine],
      };

      var afterImage = {
        columns: images.after.length ? [...images.after] : [{ text: 'Não há registro fotográfico da Sinalização.' }],
      };

      var afterImageBreakLine = {
        columns: [...images.afterBreakLine],
      };

      const afterStack = {
        stack: [afterImage, afterImageBreakLine],
      };
    }

    const imageTitle = (title) => {
      return {
        text: title,
        bold: true,
        width: 'auto',
        alignText: 'center',
        margin: [0, 5, 0, 5],
      };
    };

    // Enviando os componentes para o PDF
    this.docContent.push([
      {
        stack: [signName, createdAt, activityAddress, contractItem, supportItem, dimensionType, dimensionTable],
        unbreakable: true,
      },
      {
        stack: [
          imageTitle('Foto do Local'),
          { canvas: [{ type: 'line', x1: 0, y1: 2, x2: 595 - 2 * 40, y2: 2, lineWidth: 0.5 }] },
          afterImage,
          afterImageBreakLine,
        ],
        unbreakable: true,
      },
      {
        stack: [
          imageTitle('Foto da Sinalização'),
          { canvas: [{ type: 'line', x1: 0, y1: 2, x2: 595 - 2 * 40, y2: 2, lineWidth: 0.5 }] },
          beforeImage,
          beforeImageBreakLine,
        ],
        unbreakable: true,
      },
    ]);
  }

  getTableMeasurement(sign, financial?): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (sign?.type == SignalingTypeEnum.Horizontal) {
        var measurements = [];
        let index = 1;
        let totalCost = 0;
        let totalDimension = 0;
        // Caso tenha medições como true
        if (sign?.measurements) {
          this._reportService.getHorizontalSignalingMeasurements(sign?.id).subscribe((res: any[]) => {
            if (res.length) {
              for (let measure of res) {
                let body = [
                  { text: index.toString(), border: [false, false, false, false] },
                  { text: formatDate(sign?.createdAt, 'dd/MM/yyyy', 'pt-BR'), border: [false, false, false, false] },
                  { text: measure.units || 0, border: [false, false, false, false] },
                  { text: (Math.round(measure.dimensionY * 100) / 100).toFixed(2), border: [false, false, false, false] },
                  { text: (Math.round(measure.dimensionX * 100) / 100).toFixed(2), border: [false, false, false, false] },
                  { text: (Math.round(measure.dimension * 100) / 100).toFixed(2), border: [false, false, false, false] },
                  {
                    text: financial ? formatCurrency(measure.dimension * sign?.contractSignItem.unitValue, 'pt-BR', 'R$') : '',
                    border: [false, false, false, false],
                  }
                ]

                measurements.push(body);
                totalCost += measure.dimension * sign?.contractSignItem.unitValue;
                totalDimension += measure.dimension;
                index++;
              }
            }
            // Caso tenha medições como true, mas a lista esteja vazia
            else {
              totalCost = sign?.dimension * sign?.contractSignItem.unitValue;
              totalDimension = sign?.dimension;
              let body = [
                { text: index.toString(), border: [false, false, false, false] },
                { text: formatDate(sign?.createdAt, 'dd/MM/yyyy', 'pt-BR'), border: [false, false, false, false] },
                { text: sign?.quantity || 0, border: [false, false, false, false] },
                { text: (Math.round(sign?.dimensionY * 100) / 100).toFixed(2), border: [false, false, false, false] },
                { text: (Math.round(sign?.dimensionX * 100) / 100).toFixed(2), border: [false, false, false, false] },
                { text: (Math.round(sign?.dimension * 100) / 100).toFixed(2), border: [false, false, false, false] },
                {
                  text: financial ? formatCurrency(sign?.dimension * sign?.contractSignItem.unitValue, 'pt-BR', 'R$') : '',
                  border: [false, false, false, false],
                }
              ]

              measurements.push(body);
            }
            let body = [
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
              { text: 'Total executado:', border: [false, false, false, false], alignment: 'right', bold: true },
              { text: totalDimension.toFixed(2), border: [false, false, false, false], bold: true },
              { text: financial ? formatCurrency(totalCost, 'pt-BR', 'R$') : '', border: [false, false, false, false], bold: true }
            ]

            measurements.push(body);
            resolve(measurements);
          });
        }
        // Caso em que medições é false, não faz requisição
        else {
          totalCost = sign?.dimension * sign?.contractSignItem.unitValue;
          totalDimension = sign?.dimension;
          let body = [
            { text: index.toString(), border: [false, false, false, false] },
            { text: formatDate(sign?.createdAt, 'dd/MM/yyyy', 'pt-BR'), border: [false, false, false, false] },
            { text: sign?.quantity || 0, border: [false, false, false, false] },
            { text: (Math.round(sign?.dimensionY * 100) / 100).toFixed(2), border: [false, false, false, false] },
            { text: (Math.round(sign?.dimensionX * 100) / 100).toFixed(2), border: [false, false, false, false] },
            { text: (Math.round(sign?.dimension * 100) / 100).toFixed(2), border: [false, false, false, false] },
            {
              text: financial ? formatCurrency(sign?.dimension * sign?.contractSignItem.unitValue, 'pt-BR', 'R$') : '',
              border: [false, false, false, false],
            }
          ]

          measurements.push(body);

          let row = [
            { text: '', border: [false, false, false, false] },
            { text: '', border: [false, false, false, false] },
            { text: '', border: [false, false, false, false] },
            { text: '', border: [false, false, false, false] },
            { text: 'Total executado:', border: [false, false, false, false], alignment: 'right', bold: true },
            { text: totalDimension.toFixed(2), border: [false, false, false, false], bold: true },
            { text: financial ? formatCurrency(totalCost, 'pt-BR', 'R$') : '', border: [false, false, false, false], bold: true }
          ]

          measurements.push(row);
          resolve(measurements);
        }
      }
      // Vertical e Device entra aqui
      else {
        let columns = [
          { text: '1', border: [false, false, false, false] },
          { text: formatDate(sign?.createdAt, 'dd/MM/yyyy', 'pt-BR'), border: [false, false, false, false] },
          {
            text: sign?.type == SignalingTypeEnum.Vertical ? '-' : sign?.units || sign?.quantity, border: [false, false, false, false],
          },
          {
            text: sign?.type == SignalingTypeEnum.Vertical ? sign?.dimensionY.toFixed(2) : '-',
            border: [false, false, false, false],
          },
          {
            text: sign?.type == SignalingTypeEnum.Vertical ? sign?.dimensionX.toFixed(2) : '-',
            border: [false, false, false, false],
          },
          {
            text:
              sign?.type == SignalingTypeEnum.Vertical ? sign?.dimension.toFixed(2) : '-',
            border: [false, false, false, false],
          },
          { text: financial ? formatCurrency(sign?.cost, 'pt-BR', 'R$') : '', border: [false, false, false, false] }
        ]

        let body = [
          { text: '', border: [false, false, false, false] },
          { text: '', border: [false, false, false, false] },
          { text: '', border: [false, false, false, false] },
          { text: '', border: [false, false, false, false] },
          { text: 'Total executado:', border: [false, false, false, false], alignment: 'right', bold: true },
          { text: sign?.dimension.toFixed(2), border: [false, false, false, false], bold: true },
          {
            text: financial ? formatCurrency(sign?.cost, 'pt-BR', 'R$') : '', border: [false, false, false, false], bold: true
          }
        ]
        resolve([columns, body]);
      }
    });
  }

  // Metodo que cria o relatorio
  async createPDF(type: SystemModuleEnum, sinalesReport: boolean): Promise<string> {
    return new Promise(async (resolve, reject) => {
      // Header do documento
      let header: any = {
        columns: [
          [
            {
              alignment: 'left',
              width: '*',
              stack: [
                {
                  image: await this.getBase64ImageFromURL('assets/icons/logo-hellius.svg', false),
                  width: environment.atmanLogo ? 60 : 120,
                  margin: [40, 20, 0, 0],
                },
                {
                  text: `Relatório Fotográfico de ${type == SystemModuleEnum.Task ? 'Tarefas'
                    : type == SystemModuleEnum.ServiceOrder ? 'Ordem de Serviço'
                      : type == SystemModuleEnum.Routes ? 'Rotas' : 'Catálogo'}`,
                  width: 'auto',
                  margin: [40, 5, 0, 0],
                  bold: true,
                  fontSize: 8,
                },
              ],
            },
          ],

        ],
      };
      if (environment.atmanLogo && sinalesReport) {
        header.columns.push([
          {
            alignment: 'right',
            width: '*',
            columns: [
              {
                text: `Sinales - Sinalização Espírito Santo Ltda.\nRua Nestor Guisso, 96, Boa Vista, Serra, ES - CEP 29161-019\nCNPJ: 36.377.091/0001-26  Telefone: (27) 3398-5656`,
                width: '*',
                fontSize: 7,
                margin: [-50, 18, 35, 0],
                alignment: 'right',
              },
              {
                image: await this.getBase64ImageFromURL('assets/icons/logo-sinales.png'),
                width: 90,
                margin: [0, 10, 18, 0],
              },
            ],
          },
        ])
      }
      const author = environment.atmanLogo ? environment.softwareName + '- Atman Systems' : 'Software de Gestão HVD'
      const keywords = 'relatório custo tarefa' + environment.softwareName;

      const docDefinition = {
        header: header,
        footer: (current, counter) => {
          return {
            columns: [
              {
                text: [
                  { text: 'Aviso: ', bold: true },
                  'Os dados deste relatório podem, ou não, corresponder a\nvalores ou números que condizem com a realidade.',
                ],
                alignment: 'left',
                width: '*',
                fontSize: 6,
                margin: [40, 0, 0, 0],
              },
              {
                text: `Página ${current} de ${counter}`,
                alignment: 'right',
                width: '*',
                margin: [0, 0, 40, 0],
                fontSize: 8,
              },
            ],
          };
        },
        info: {
          title: 'Relatório de Fotográfico ' + formatDate(new Date(), 'dd/MM/yyyy', 'pt-BR').toString(),
          author,
          subject: 'Relatório fotográfico de serviço',
          keywords,
        },
        content: [this.docContent],
        pageMargins: [40, 60, 40, 60],
        defaultStyle: {
          fontSize: 10,
          color: '#404042',
        },
      };
      const download = await pdfMake.createPdf(docDefinition);
      download.download(
        'relatorio fotografico de ' + (type == SystemModuleEnum.Task ? 'tarefas'
          : type == SystemModuleEnum.ServiceOrder ? 'ordem de serviço'
            : type == SystemModuleEnum.Routes ? 'rotas' : 'catálogo'),
        (event) => {
          this.docContent = [];
          resolve('done');
        },
      );
    });
  }

  // Recebe imagem, converte para base64 e guarda dentro do formulário
  async getBase64ImageFromURL(url: string, external?: boolean): Promise<string> {
    return new Promise((resolve, reject) => {
      const fetchImage = async () => {
        try {
          const response = await fetch(url, { cache: 'no-cache' });
          if (!response.ok) {
            throw new Error(`Erro na requisição: ${response.status} ${response.statusText}`);
          }
          const blob = await response.blob();
          const reader = new FileReader();
  
          reader.onload = () => {
            resolve(reader.result as string);
          };
  
          reader.onerror = (e) => {
            reject(`Erro ao ler blob: ${JSON.stringify(e)}`);
          };
  
          reader.readAsDataURL(blob);
        } catch (error) {
          reject(`Erro ao fazer fetch da imagem: ${error}`);
        }
      };
  
      if (external) {
        fetchImage();
      } else {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = url;
  
        img.onload = () => {
          try {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            if (!context) throw new Error('Canvas context não disponível.');
  
            canvas.height = img.height;
            canvas.width = img.width;
  
            // Preencher fundo com branco antes de desenhar a imagem
            context.fillStyle = '#FFFFFF';
            context.fillRect(0, 0, canvas.width, canvas.height);
            context.drawImage(img, 0, 0);
  
            resolve(canvas.toDataURL('image/jpeg'));
          } catch (error) {
            reject(`Erro ao processar imagem: ${error}`);
          }
        };
  
        img.onerror = (error) => {
          reject(`Erro ao carregar imagem: ${error}`);
        };
      }
    });
  };
  
  numberOS(element, type, orderList: Array<ServiceOrder>) {
    if (type == 'date') {
      return (orderList.findIndex(os => os.id == element.id) + 1);
    } else {
      let value = '';
      orderList.forEach((os, index) => {
        if (os.requester == element) {
          value += '(' + (index + 1) + ') ';
        }
      });

      return value;
    }
  }
}
