<div class="pt-3">
  <div [ngStyle]="{ 'flex-direction': displayDirection }">
    <div class="col-auto px-0 mb-2" *ngIf="signType != typeSignEnum.Device">
      <div class="custom-control custom-radio">
        <input
          type="radio"
          [id]="'dimensionInformation1' + switchId"
          [name]="'dimensionInformation' + switchId"
          class="custom-control-input"
          [checked]="checkedDimensionType(dimensionInformationEnum.Envolvente)"
          (change)="changeDimensionType(dimensionInformationEnum.Envolvente); emitDimensionInformation()"
          [disabled]="projectSignalingItemId || stockItemId"
        />
        <label class="custom-control-label pl-2" [for]="'dimensionInformation1' + switchId">Área Envolvente</label>
      </div>
    </div>

    <!-- Dimensões Horizontais -->
    <ng-container
      *ngIf="signType == typeSignEnum.Horizontal && checkedDimensionType(dimensionInformationEnum.Envolvente)"
    >
      <div class="box-dimension-list">
        <ng-container *ngFor="let dimensionSetting of filterHorizontalDimensionList()">
          <div class="box-dimension-values" (click)="setDimensions(dimensionSetting)">
            <span class="dimension-values">
              {{ dimensionSetting?.dimensionY ? dimensionSetting?.dimensionY + 'm' : '' }}
              {{ dimensionSetting?.dimensionX && dimensionSetting?.dimensionY ? ' x ' : '' }}
              {{ dimensionSetting?.dimensionX ? dimensionSetting?.dimensionX + 'm' : '' }}
              {{
                (dimensionSetting?.dimensionX || dimensionSetting?.dimensionY) && dimensionSetting?.dimension
                  ? ' = '
                  : ''
              }}
              {{ dimensionSetting?.dimension ? parseFloat(dimensionSetting?.dimension.toFixed(3)) + 'm²' : '' }}
            </span>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <!-- Dimensões Horizontais -->

    <!-- Dimensões Verticais -->
    <ng-container
      *ngIf="signType == typeSignEnum.Vertical && checkedDimensionType(dimensionInformationEnum.Envolvente)"
    >
      <div class="box-dimension-list">
        <ng-container *ngFor="let dimensionSetting of filterTrafficDimensionList()">
          <div class="box-dimension-values" (click)="setDimensions(dimensionSetting)">
            <span class="dimension-values">
              {{ dimensionSetting?.dimensionY ? dimensionSetting?.dimensionY + 'm' : '' }}
              {{ dimensionSetting?.dimensionX && dimensionSetting?.dimensionY ? ' x ' : '' }}
              {{ dimensionSetting?.dimensionX ? dimensionSetting?.dimensionX + 'm' : '' }}
              {{ dimensionSetting?.diameter ? dimensionSetting?.diameter + 'm' : '' }}
              {{ dimensionSetting?.sideLength ? dimensionSetting?.sideLength + 'm' : '' }}
              {{
                (dimensionSetting?.dimensionX ||
                  dimensionSetting?.dimensionY ||
                  dimensionSetting?.diameter ||
                  dimensionSetting?.sideLength) &&
                dimensionSetting?.dimension
                  ? ' = '
                  : ''
              }}
              {{ dimensionSetting?.dimension ? parseFloat(dimensionSetting?.dimension.toFixed(3)) + 'm²' : '' }}
            </span>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <!-- Dimensões Verticais -->

    <div
      class="d-flex"
      *ngIf="checkedDimensionType(dimensionInformationEnum.Envolvente) || signType == typeSignEnum.Device"
    >
      <div *ngIf="signType == typeSignEnum.Device" class="pl-0 col-4">
        <label style="width: 80px">Unidades*</label>
        <input
          [id]="'units' + switchId"
          *ngIf="signType == typeSignEnum.Device"
          type="text"
          class="form-control"
          [ngClass]="{ 'select-readonly': dimensionLocked1 || projectSignalingItemId || stockItemId }"
          [disabled]="checkedDimensionType(dimensionInformationEnum.Efetiva)"
          (value)="(dimensionInformationState.units)"
          (change)="dimensionInformationState.units = $event.target.value; emitDimensionInformation()"
          [mask]="'0*.00000'"
        />
      </div>

      <div *ngIf="signType == typeSignEnum.Device" class="col-8 pr-0">
        <label>Extensão Aplicada (m)*</label>
        <input
          [id]="'extension' + switchId"
          [mask]="'0*.00000'"
          type="text"
          class="form-control"
          [ngClass]="{ 'select-readonly': dimensionLocked1 || projectSignalingItemId || stockItemId }"
          [disabled]="checkedDimensionType(dimensionInformationEnum.Efetiva)"
          (value)="(dimensionSetting?.dimension)"
          (change)="dimensionInformationState.dimension = $event.target.value; emitDimensionInformation()"
        />
      </div>

      <ng-container *ngIf="signType != typeSignEnum.Device">
        <div>
          <label
            [ngClass]="{ 'label-disabled': checkedDimensionType(dimensionInformationEnum.Efetiva) }"
            style="width: 80px"
            >Altura (m)*</label
          >
          <input
            [mask]="'0*.00000'"
            class="form-control"
            [id]="'dimensionY1' + switchId"
            #dimensionY
            type="text"
            [ngClass]="{ 'select-readonly': dimensionLocked1 || projectSignalingItemId || stockItemId }"
            [disabled]="checkedDimensionType(dimensionInformationEnum.Efetiva)"
            [value]="
              checkedDimensionType(dimensionInformationEnum.Envolvente) ? dimensionInformationState.dimensionY : null
            "
            (change)="dimensionInformationState.dimensionY = $event.target.value; calculateDimension('totalDimension1')"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="signType != typeSignEnum.Device">
        <h4 class="d-flex align-items-end mx-2" style="height: 60px">x</h4>
        <div>
          <label
            [ngClass]="{ 'label-disabled': checkedDimensionType(dimensionInformationEnum.Efetiva) }"
            style="width: 80px"
            >Largura (m)*</label
          >
          <input
            [mask]="'0*.00000'"
            type="text"
            class="form-control"
            [id]="'dimensionX1' + switchId"
            [ngClass]="{ 'select-readonly': dimensionLocked1 || projectSignalingItemId || stockItemId }"
            [disabled]="checkedDimensionType(dimensionInformationEnum.Efetiva)"
            #dimensionX
            [value]="
              checkedDimensionType(dimensionInformationEnum.Envolvente) ? dimensionInformationState.dimensionX : null
            "
            (change)="dimensionInformationState.dimensionX = $event.target.value; calculateDimension('totalDimension1')"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="signType != typeSignEnum.Device">
        <h4 class="d-flex align-items-end mx-2" style="height: 60px">=</h4>
        <div class="col-5 pr-0 pl-1">
          <label
            [ngClass]="{ 'label-disabled': checkedDimensionType(dimensionInformationEnum.Efetiva) }"
            style="width: 80px"
            >Total (m²)*</label
          >
          <input
            [id]="'totalDimension1' + switchId"
            [mask]="'0*.00000'"
            [ngClass]="{ 'select-readonly': dimensionLocked1 || projectSignalingItemId || stockItemId }"
            type="text"
            class="form-control"
            [disabled]="checkedDimensionType(dimensionInformationEnum.Efetiva)"
            [readonly]="dimensionInformationState.dimensionX || dimensionInformationState.dimensionY"
            [value]="
              checkedDimensionType(dimensionInformationEnum.Envolvente) ? dimensionInformationState.dimension : null
            "
            (change)="dimensionInformationState.dimension = $event.target.value; emitDimensionInformation()"
          />
        </div>
      </ng-container>
    </div>
  </div>

  <div [ngStyle]="{ 'flex-direction': displayDirection }" *ngIf="signType != typeSignEnum.Device">
    <div class="col-auto px-0 mb-2">
      <div class="custom-control custom-radio">
        <input
          type="radio"
          [id]="'dimensionInformation2' + switchId"
          [name]="'dimensionInformation' + switchId"
          class="custom-control-input"
          [disabled]="projectSignalingItemId || stockItemId"
          [checked]="checkedDimensionType(dimensionInformationEnum.Efetiva)"
          (change)="changeDimensionType(dimensionInformationEnum.Efetiva); emitDimensionInformation()"
        />
        <label class="custom-control-label pl-2" [for]="'dimensionInformation2' + switchId">Área Efetiva</label>
      </div>
    </div>

    <!-- Dimensões Horizontais -->
    <ng-container *ngIf="signType == typeSignEnum.Horizontal && checkedDimensionType(dimensionInformationEnum.Efetiva)">
      <div class="box-dimension-list">
        <ng-container *ngFor="let dimensionSetting of filterHorizontalDimensionList()">
          <div class="box-dimension-values" (click)="setDimensions(dimensionSetting)">
            <span class="dimension-values">
              {{ dimensionSetting?.dimensionX ? dimensionSetting?.dimensionX + 'm' : '' }}
              {{ dimensionSetting?.dimensionX && dimensionSetting?.dimensionY ? ' x ' : '' }}
              {{ dimensionSetting?.dimensionY ? dimensionSetting?.dimensionY + 'm' : '' }}
              {{
                (dimensionSetting?.dimensionX || dimensionSetting?.dimensionY) && dimensionSetting?.dimension
                  ? ' = '
                  : ''
              }}
              {{ dimensionSetting?.dimension ? parseFloat(dimensionSetting?.dimension.toFixed(3)) + 'm²' : '' }}
            </span>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <!-- Dimensões Horizontais -->

    <!-- Dimensões Verticais -->
    <ng-container *ngIf="signType == typeSignEnum.Vertical && checkedDimensionType(dimensionInformationEnum.Efetiva)">
      <div class="box-dimension-list">
        <ng-container *ngFor="let dimensionSetting of filterTrafficDimensionList()">
          <div class="box-dimension-values" (click)="setDimensions(dimensionSetting)">
            <span class="dimension-values">
              {{ dimensionSetting?.dimensionX ? dimensionSetting?.dimensionX + 'm' : '' }}
              {{ dimensionSetting?.dimensionX && dimensionSetting?.dimensionY ? ' x ' : '' }}
              {{ dimensionSetting?.dimensionY ? dimensionSetting?.dimensionY + 'm' : '' }}
              {{ dimensionSetting?.diameter ? dimensionSetting?.diameter + 'm' : '' }}
              {{ dimensionSetting?.sideLength ? dimensionSetting?.sideLength + 'm' : '' }}
              {{
                (dimensionSetting?.dimensionX ||
                  dimensionSetting?.dimensionY ||
                  dimensionSetting?.diameter ||
                  dimensionSetting?.sideLength) &&
                dimensionSetting?.dimension
                  ? ' = '
                  : ''
              }}
              {{ dimensionSetting?.dimension ? parseFloat(dimensionSetting?.dimension.toFixed(3)) + 'm²' : '' }}
            </span>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <!-- Dimensões Verticais -->

    <!-- Dimensões Horizontais -->
    <div class="d-flex flex-column pl-4 mb-2" *ngIf="checkedDimensionType(dimensionInformationEnum.Efetiva) && hasCadence">
      <label>Cadência</label>
      <div class="d-flex flex-row" style="width: 180px">
        <input class="form-control" type="number" [id]="'dash' + switchId"
          (change)="dimensionInformationState.dash = $event.target.value; calculateUnits()"
          title="Quantidade pintada em m">
        <h4 class="d-flex align-items-end mx-2" style="height: 34px">x</h4>
        <input class="form-control" type="number" [id]="'spacing' + switchId"
          (change)="dimensionInformationState.spacing = $event.target.value; calculateUnits()"
          title="Quantidade não pintada em m">
      </div>
    </div>

    <div class="d-flex pl-4" *ngIf="checkedDimensionType(dimensionInformationEnum.Efetiva)">
      <div *ngIf="signType == typeSignEnum.Horizontal" class="pl-0">
        <label
          [ngClass]="{ 'label-disabled': checkedDimensionType(dimensionInformationEnum.Envolvente) }"
          style="width: 80px"
          >Unidades*</label
        >
        <input
          [mask]="'0*.00000'"
          [id]="'quantity' + switchId"
          type="text"
          class="form-control"
          [ngClass]="{ 'select-readonly': dimensionLocked2 || projectSignalingItemId || stockItemId }"
          [disabled]="checkedDimensionType(dimensionInformationEnum.Envolvente)"
          [value]="checkedDimensionType(dimensionInformationEnum.Efetiva) ? dimensionInformationState.quantity : null"
          (change)="dimensionInformationState.quantity = $event.target.value; calculateDimension('totalDimension2'); clearCadence()"
        />
      </div>

      <ng-container
        *ngIf="
          signType == typeSignEnum.Horizontal || signCode == verticalSignaling.R1 || signCode == verticalSignaling.R2
        "
      >
        <h4 *ngIf="signType != typeSignEnum.Vertical" class="d-flex align-items-end mx-2" style="height: 60px">x</h4>
        <div>
          <label
            [ngClass]="{ 'label-disabled': checkedDimensionType(dimensionInformationEnum.Envolvente) }"
            [ngStyle]="{ width: signType == typeSignEnum.Horizontal ? '80px' : '180px' }"
          >
            {{ signType == typeSignEnum.Horizontal ? 'Altura (m)*' : 'Altura da sinalização (m)' }}</label
          >
          <input
            [id]="'dimensionY2' + switchId"
            type="text"
            class="form-control"
            [mask]="'0*.00000'"
            #dimensionY
            [ngClass]="{ 'select-readonly': dimensionLocked2 || projectSignalingItemId || stockItemId }"
            [disabled]="checkedDimensionType(dimensionInformationEnum.Envolvente)"
            [value]="
              checkedDimensionType(dimensionInformationEnum.Efetiva) ? dimensionInformationState.dimensionY : null
            "
            (change)="dimensionInformationState.dimensionY = $event.target.value; calculateDimension('totalDimension2')"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="signType == typeSignEnum.Horizontal">
        <h4 class="d-flex align-items-end mx-2" style="height: 60px">x</h4>
        <div>
          <label
            [ngClass]="{ 'label-disabled': checkedDimensionType(dimensionInformationEnum.Envolvente) }"
            style="width: 80px"
            >Largura (m)*</label
          >
          <input
            [id]="'dimensionX2' + switchId"
            class="form-control"
            [mask]="'0*.00000'"
            #dimensionX
            type="text"
            [ngClass]="{ 'select-readonly': dimensionLocked2 || projectSignalingItemId || stockItemId }"
            [disabled]="checkedDimensionType(dimensionInformationEnum.Envolvente)"
            [value]="
              checkedDimensionType(dimensionInformationEnum.Efetiva) ? dimensionInformationState.dimensionX : null
            "
            (change)="dimensionInformationState.dimensionX = $event.target.value; calculateDimension('totalDimension2')"
          />
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          signType == typeSignEnum.Vertical && !(signCode == verticalSignaling.R1 || signCode == verticalSignaling.R2)
        "
      >
        <div>
          <label
            [ngClass]="{ 'label-disabled': checkedDimensionType(dimensionInformationEnum.Envolvente) }"
            style="width: 110px"
          >
            {{
              !(signCode == verticalSignaling.R1 || signCode == verticalSignaling.R2) &&
              verticalType == verticalSignalingType.Regulation
                ? 'Diâmetro (m)*'
                : 'Lado (m)*'
            }}</label
          >
          <input
            *ngIf="verticalType == verticalSignalingType.Regulation; else elseDiameter"
            [mask]="'0*.00000'"
            #dimensionDiameter
            [id]="'diameter' + switchId"
            type="text"
            class="form-control"
            [ngClass]="{ 'select-readonly': dimensionLocked2 || projectSignalingItemId || stockItemId }"
            [disabled]="checkedDimensionType(dimensionInformationEnum.Envolvente)"
            [value]="checkedDimensionType(dimensionInformationEnum.Efetiva) ? dimensionInformationState.diameter : null"
            (change)="dimensionInformationState.diameter = $event.target.value; calculateDimension('totalDimension2')"
          />
          <ng-template #elseDiameter>
            <input
              #dimensionSide
              type="text"
              class="form-control"
              [mask]="'0*.00000'"
              [id]="'sideLength' + switchId"
              [ngClass]="{ 'select-readonly': dimensionLocked2 || projectSignalingItemId || stockItemId }"
              [disabled]="checkedDimensionType(dimensionInformationEnum.Envolvente)"
              [value]="
                checkedDimensionType(dimensionInformationEnum.Efetiva) ? dimensionInformationState.sideLength : null
              "
              (change)="
                dimensionInformationState.sideLength = $event.target.value; calculateDimension('totalDimension2')
              "
            />
          </ng-template>
        </div>
      </ng-container>

      <ng-container>
        <h4 class="d-flex align-items-end mx-2" style="height: 60px">=</h4>
        <div class="col-5 pr-0 pl-1">
          <label
            [ngClass]="{ 'label-disabled': checkedDimensionType(dimensionInformationEnum.Envolvente) }"
            style="width: 80px"
            >Total (m²)*
          </label>
          <input
            [id]="'totalDimension2' + switchId"
            #totalDimension2
            [mask]="'0*.00000'"
            type="text"
            class="form-control"
            [ngClass]="{ 'select-readonly': dimensionLocked2 || projectSignalingItemId || stockItemId }"
            [disabled]="checkedDimensionType(dimensionInformationEnum.Envolvente)"
            [readonly]="
              dimensionInformationState.dimensionX ||
              dimensionInformationState.dimensionY ||
              dimensionInformationState.units ||
              dimensionInformationState.diameter ||
              dimensionInformationState.sideLength
            "
            [value]="dimensionSetting?.dimension"
            (change)="dimensionInformationState.dimension = $event.target.value; emitDimensionInformation()"
          />
        </div>
      </ng-container>
    </div>
    <!-- Dimensões Horizontais -->
  </div>
</div>
